<template>

  <div class="mb-4">
    <Table ref="table"
           name="Duplicate Codes List"
           :headers="table.header"
           url="/contract/act_info/get_duplicates/"
           :getUpdate="table.getUpdate"
           :searchable="table.searchable"
           selectable :per-page="25"
           @onLoaded="onLoad"
    >
      <template v-slot:contract_act="{row: data}">

        <div
            v-if="data.contract_act && (data.contract_act.contract && data.contract_act.contract.slug) && (data.contract_act.contract.company && data.contract_act.contract.company.slug)">
          <router-link :to="{
            name: 'counterparty_profile_contracts_acts', params: {
              contract_slug: data.contract_act.contract.slug,
              slug: data.contract_act.contract.company.slug
            }, query: {
              act: data.contract_act.slug
            }
          }">
            {{ data.contract_act.name || '***' }}
          </router-link>
        </div>
        <div v-else>
          {{ data.contract_act ? data.contract_act.name || '***' : '' }}
        </div>
      </template>
      <template v-slot:code_td="{row: data}">
        <td class="text-center" :class="data.color"
            v-if="data.contract_act && (data.contract_act.contract && data.contract_act.contract.slug) && (data.contract_act.contract.company && data.contract_act.contract.company.slug)">
          <router-link :to="{
            name: 'counterparty_profile_contracts_acts', params: {
              contract_slug: data.contract_act.contract.slug,
              slug: data.contract_act.contract.company.slug
            }, query: {
              act: data.contract_act.slug,
              code: data.code
            }
          }">
            {{ data.code }}
          </router-link>
        </td>
      </template>
    </Table>
  </div>

</template>

<script>
import Table from "@/components/tables/table.vue";

export default {
  name: "clients_list",
  components: {
    Table,
  },
  data() {
    return {
      table: {
        searchable: true,
        header: [
          {
            label: "Lot Number",
            field: "lot_number",
            align: "center",
          },
          {
            label: "Contract Act",
            field: "contract_act",
            align: "center",
            excel_data: (data) => {
              return data.contract_act ? data.contract_act.name : '***'
            }
          },
          {
            label: "Code",
            field: "code",
            align: "center",
          },
          {
            label: "Container / Wagon",
            field: "container_or_wagon",
            align: "center",
          },
        ],
      },
      current_color: 'danger',
      table_data: []
    }
  },
  methods: {
    onLoad(data) {
      let is_red = true
      this.table_data = data || []
      setTimeout(() => {
        this.$refs.table.apiData = this.table_data.map((i, index) => {
          let next = index + 1 === this.table_data.length ? null : this.table_data[index + 1]
          let prev = index === 0 ? null : this.table_data[index - 1]

          if (next) {
            if (is_red) {
              if (next.code === i.code) {
                i.color = 'bg-light'
              } else {
                i.color = 'bg-light'
                is_red = false
              }
            } else {
              if (next.code === i.code) {
                i.color = ''
              } else {
                i.color = ''
                is_red = true
              }
            }
          } else {
            if (prev) {
              i.color = prev.code === i.code ? prev.color : prev.color === '' ? 'bg-light' : ''
            } else {
              i.color = 'bg-light'
            }
          }

          return {
            ...i,
          }

        })
      }, 100)
    },
  }
}
</script>

<style scoped>

</style>