<script>
import PageHeader from "@/components/page-header.vue";
import CounterpartyList from "@/views/pages/accounts/counterparties/List.vue"
import DuplicateCodesList from "@/views/pages/accounts/counterparties/DuplicateCodesList.vue"

export default {
  name: "Index",
  components: {
    PageHeader,
    CounterpartyList,
    DuplicateCodesList
  },
  data() {
    return {
      title: "Users Management",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Clients",
          active: true,
        },
      ],
      active_tab: 'counterparties',

      tabs: [
        {
          value: 'counterparties',
          label: 'Counterparties'
        },
        {
          value: 'duplicate_codes',
          label: 'Duplicate Codes'
        },
      ]
    }
  },
  methods: {
    changeActiveTab(new_tab_name) {
      this.active_tab = new_tab_name
      localStorage.setItem('counterparty_list_active_tab', new_tab_name)
    }
  },
  mounted() {
    let active_tab = localStorage.getItem('counterparty_list_active_tab')
    if (active_tab && this.tabs.map(i => i.value).includes(active_tab)) {
      this.active_tab = active_tab
    }
  }
}
</script>

<template>
  <PageHeader :title="title" :items="items"/>

  <div class="tabs"><!---->
    <div class="">
      <ul class="nav nav-pills nav nav-pills nav-custom nav-custom-light mb-3" role="tablist"
          aria-orientation="horizontal">
        <template v-for="tab in tabs" :key="`tab_${tab.value}`">
          <li class="nav-item" role="presentation">
            <button @click="changeActiveTab(tab.value)" class="nav-link" :class="{
            'active': active_tab === tab.value
          }" role="tab">{{ tab.label }}
            </button>
          </li>
        </template>
      </ul>
    </div>
    <div class="tab-content text-muted">
      <template v-if="active_tab === 'counterparties'">
        <CounterpartyList/>
      </template>
      <template v-else-if="active_tab === 'duplicate_codes'">
        <DuplicateCodesList/>
      </template>
    </div>

  </div>

</template>

<style scoped>

</style>